var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", [
    _c("span", { staticClass: "key", style: `background:${_vm.color};` }),
    _c("p", [_vm._v(_vm._s(_vm.title))]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }