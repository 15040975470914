<template>
	<b-card class="peachpearplum-dashboard-card peachpearplum-peach" @click="$router.push('/peach-pear-plum/peach')">
		<div class="d-flex justify-content-between">
			<div class="d-flex flex-grow-1 flex-column">
				<p class="card-title h1 w100">PEACH</p>
				<div class="d-lg-flex d-sm-none d-lg-block">
					<div v-for="(labelGroup, groupIndex) in groupedLabels" :key="groupIndex" class="flex-fill">
						<div v-for="(label, index) in labelGroup" :key="index">
							<PeachDashboardKey :title="label.label" :color="chartColors[label.colorIndex]" />
						</div>
					</div>
				</div>
			</div>
			<div class="d-flex gap-6 peachpearplum-apex">
				<VueApexCharts type="donut" :options="getChartOptions(3, 'Prep')" :series="chartSeries.prep" />
				<VueApexCharts type="donut" :options="getChartOptions(4, 'Wrap')" :series="chartSeries.wrap" />
			</div>
		</div>
	</b-card>
</template>
<script>
import languageMessages from '@/mixins/languageMessages';
import PeachDashboardKey from '@/modules/peach-pear-plum/components/peach/DashboardKey';
import { getPeachResponses } from '@/cruds/peachPearPlum.crud';
import { allQuestions, categories } from '@/modules/peach-pear-plum/peachCategories.js';
import VueApexCharts from 'vue-apexcharts';

export default {
	name: 'PeachDashboardCard',
	components: {
		VueApexCharts,
		PeachDashboardKey
	},
	mixins: [languageMessages],
	data() {
		return {
			peachData: [],
			results: {
				prep: {},
				wrap: {}
			},
			labels: [],
			chartColors: [
				'#95AA98',
				'#CFDAC7',
				'#EC9C85',
				'#F8DAD2',
				'#E5CFC1',
				'#F6D1B1',
				'#C8DCDA',
				'#E5DBEE',
				'#E8EBD3',
				'#E4BDBD',
				'#DAE1EA',
				'#CCC7B3',
				'#9AAFA5'
			]
		};
	},
	computed: {
		chartSeries() {
			return {
				prep: Object.values(this.results.prep),
				wrap: Object.values(this.results.wrap)
			};
		},
		groupedLabels() {
			const groupSize = 5; // Number of items per group
			const groupedLabels = [];
			for (let i = 0; i < this.labels.length; i += groupSize) {
				const group = this.labels.slice(i, i + groupSize);
				const objectsInGroup = group.map((item, indexInGroup) => ({ label: item.label, colorIndex: i + indexInGroup, name: item.name }));
				groupedLabels.push(objectsInGroup);
			}
			return groupedLabels;
		}
	},
	async mounted() {
		this.peachData = await getPeachResponses();
		this.prepData();
	},
	methods: {
		getChartOptions(formId, title) {
			return {
				colors: this.chartColors,
				stroke: {
					show: false
				},
				chart: {
					offsetY: -20,
					width: 380,
					height: 260,
					type: 'donut'
				},
				tooltip: {
					custom: ({ series, seriesIndex, dataPointIndex, w }) => {
						return `${this.labels[seriesIndex].label} - ${series[seriesIndex]} Points`;
					}
				},
				plotOptions: {
					pie: {
						startAngle: -90,
						endAngle: 270,
						donut: {
							labels: {
								show: true,
								name: {
									show: true,
									fontSize: '22px',
									color: '#dfsda',
									offsetY: 15,
									formatter: (w) => {
										if (w === 'Points') return this.FormMSG(99, 'Points');
										return w.label;
									}
								},
								value: {
									show: true,
									fontSize: '25px',
									fontWeight: 'bold',
									color: undefined,
									offsetY: -20,
									formatter: (val) => {
										return val;
									}
								},
								total: {
									show: true,
									label: 'Points',
									color: '#373d3f',
									formatter: (w) => {
										return w.globals.seriesTotals.reduce((a, b) => {
											return a + b;
										}, 0);
									}
								}
							}
						}
					}
				},
				dataLabels: {
					enabled: false
				},
				legend: {
					show: false
				},
				title: {
					text: title,
					align: 'left',
					offsetX: -10,
					offsetY: 125,
					floating: false,
					style: {
						fontSize: '20px',
						fontWeight: 'bold',
						color: '#615E83'
					},
					formatter: (v) => {
						return this.FormMSG(formId, title);
					}
				},
				labels: this.labels
			};
		},
		findQuestionByFormId(questionId) {
			return allQuestions.find((x) => x.formId === questionId);
		},
		setCategories(categoriesArray) {
			const transformedCategories = {};
			for (const category of categoriesArray) {
				this.labels.push({ label: this.FormMSG(category.formId, category.title), name: category.name });
				transformedCategories[category.name] = 0;
			}
			return transformedCategories;
		},

		prepData() {
			const allCategories = this.setCategories(categories);
			this.results.wrap = { ...allCategories };
			this.results.prep = { ...allCategories };
			for (let i = 0; i < this.peachData.length; i++) {
				const data = this.peachData[i];
				if (data.wrap !== 1 && data.prep !== 1) continue;
				const { availablePoints = 0 } = this.findQuestionByFormId(data.questionId);
				const category = data.questionSection;
				if (data.wrap === 1) {
					this.results.wrap[category] += availablePoints;
				}
				if (data.prep === 1) {
					this.results.prep[category] += availablePoints;
				}
			}
		}
	}
};
</script>
