<template>
	<div>
		<PeachDashboardCard />
		<!-- <PearDashboardCard /> -->
		<!-- <b-card class="peachpearplum-dashboard-card peachpearplum-pear" @click="$router.push('/reporting/pear')">
			<p class="card-title h1">PEAR</p>
		</b-card>
		<b-card class="peachpearplum-dashboard-card peachpearplum-plum" @click="$router.push('/reporting/plum')">
			<p class="card-title h1">PLUM</p>
		</b-card> -->
	</div>
</template>
<script>
import languageMessages from '@/mixins/languageMessages';
import PeachDashboardCard from '@/modules/peach-pear-plum/components/peach/DashboardCard';
// import PearDashboardCard from '@/modules/peach-pear-plum/components/pear/DashboardCard';
export default {
	name: 'PeachPearPlumDashboard',
	mixins: [languageMessages],
	components: {
		PeachDashboardCard
		// PearDashboardCard
	}
};
</script>
