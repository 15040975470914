<template>
	<section>
		<span class="key" :style="`background:${color};`"></span>
		<p>{{ title }}</p>
	</section>
</template>
<script>
export default {
	name: 'DashboardKey',
	props: {
		title: {
			type: String,
			default: ''
		},
		color: {
			type: String,
			default: '#fff'
		}
	}
};
</script>
<style scoped>
section {
	display: inline-flex;
	flex: 1 1 auto;
	color: #615e83;
	font-size: 18px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
	align-items: center;
	padding: 5px;
}
.key {
	width: 15px;
	height: 15px;
	margin-right: 10px;
	display: inline-block;
	border-radius: 10px;
}
p {
	display: inline-flex;
	flex: 1 1 auto;
	margin-bottom: 0;
}
</style>
