var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-card",
    {
      staticClass: "peachpearplum-dashboard-card peachpearplum-peach",
      on: {
        click: function ($event) {
          return _vm.$router.push("/peach-pear-plum/peach")
        },
      },
    },
    [
      _c("div", { staticClass: "d-flex justify-content-between" }, [
        _c("div", { staticClass: "d-flex flex-grow-1 flex-column" }, [
          _c("p", { staticClass: "card-title h1 w100" }, [_vm._v("PEACH")]),
          _c(
            "div",
            { staticClass: "d-lg-flex d-sm-none d-lg-block" },
            _vm._l(_vm.groupedLabels, function (labelGroup, groupIndex) {
              return _c(
                "div",
                { key: groupIndex, staticClass: "flex-fill" },
                _vm._l(labelGroup, function (label, index) {
                  return _c(
                    "div",
                    { key: index },
                    [
                      _c("PeachDashboardKey", {
                        attrs: {
                          title: label.label,
                          color: _vm.chartColors[label.colorIndex],
                        },
                      }),
                    ],
                    1
                  )
                }),
                0
              )
            }),
            0
          ),
        ]),
        _c(
          "div",
          { staticClass: "d-flex gap-6 peachpearplum-apex" },
          [
            _c("VueApexCharts", {
              attrs: {
                type: "donut",
                options: _vm.getChartOptions(3, "Prep"),
                series: _vm.chartSeries.prep,
              },
            }),
            _c("VueApexCharts", {
              attrs: {
                type: "donut",
                options: _vm.getChartOptions(4, "Wrap"),
                series: _vm.chartSeries.wrap,
              },
            }),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }